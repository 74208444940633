document.addEventListener('DOMContentLoaded', () => {
    const className = 'catalog-menu-accordion';
    const container = document.querySelector(`.${className}`);

    if (container) {
        const sections = container.querySelectorAll(`.${className}__section`);

        const selectSection = (selectedSection: HTMLElement) => {
            sections.forEach((section) => {
                section.classList.toggle(`${className}__section--active`, selectedSection === section)
            })
        }

        const setMenuCookieByLink = (link: HTMLAnchorElement) => {
            const groupId = link.dataset.groupId
            const categoryId = link.dataset.categoryId

            if (groupId && categoryId) {
                document.cookie = `selected-menu-group=${groupId}; path=/; max-age=2628000`
                document.cookie = `selected-menu-group-item=${categoryId}; path=/; max-age=2628000`
            }
        }

        container.addEventListener('click', (e) => {
            const target = e.target as HTMLElement;
            const section = target.closest(`.${className}__section`) as HTMLElement|null;
            const link = target.closest(`.${className}__link`) as HTMLAnchorElement|null;

            if (link) {
                setMenuCookieByLink(link)
            }

            if (section) {
                selectSection(section)
            }
        })
    }
})
